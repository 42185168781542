"use strict";
'use es6';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = require("../index");
var _promiseClient = _interopRequireDefault(require("../adapters/promiseClient"));
var core = _interopRequireWildcard(require("../middlewares/core"));
var hubapi = _interopRequireWildcard(require("../middlewares/hubapi"));
var debug = _interopRequireWildcard(require("../middlewares/debug"));
var user = _interopRequireWildcard(require("../middlewares/user"));
var _params = require("../helpers/params");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var _default = exports.default = (0, _promiseClient.default)((0, _index.createStack)(core.services, hubapi.defaults, user.recyclePromise, debug.allowTimeoutOverride, user.portalIdBody, core.bodyType('application/x-www-form-urlencoded', _params.stringify), user.hubUserInfoEndpointTest, core.httpsOnly, debug.rewriteUrl, core.reportOptionsError, user.logoutOnUnauthorizedOrForbidden, core.enableMigrationCheckBypass, core.redirectOnMigrationInProgress, core.redirectOnPortalMoved, user.retryOnError, core.jsonResponse, user.redirectSuspendedUsers));
module.exports = exports.default;