"use strict";
'use es6';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.withStaticAppInfo = exports.ensureStaticAppInfo = void 0;
var _core = require("./core");
var _url = require("../helpers/url");
var params = _interopRequireWildcard(require("../helpers/params"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
const formatVersion = version => {
  if (version == null) {
    return 'unknown';
  }
  if (version === 'static') {
    return 'dev';
  }
  return version.replace('static-', '');
};
const withStaticAppInfo = options => options.appInfo ? (0, _core.header)('X-HubSpot-Static-App-Info', `${options.appInfo.name}@${formatVersion(options.appInfo.version)}`)(options) : options;
exports.withStaticAppInfo = withStaticAppInfo;
const ensureStaticAppInfo = options => {
  if (!options.appInfo) return options;
  const queries = params.parse((0, _url.parseUrl)(options.url).query);
  return (0, _core.query)({
    hs_static_app: queries.hs_static_app || options.appInfo.name,
    hs_static_app_version: queries.hs_static_app_version || `${formatVersion(options.appInfo.version)}`
  })(options);
};
exports.ensureStaticAppInfo = ensureStaticAppInfo;