"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDomain = getDomain;
exports.getDomainPrefix = getDomainPrefix;
exports.getEnvPostfix = getEnvPostfix;
exports.getHubletDomainPostfix = getHubletDomainPostfix;
exports.getHubletPostfix = getHubletPostfix;
exports.getPathPrefix = getPathPrefix;
exports.getSubDomain = getSubDomain;
exports.getTld = getTld;
var _enviro = _interopRequireDefault(require("enviro"));
var Hublets = _interopRequireWildcard(require("../hublets"));
var _subdomains = require("../subdomains");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function getHubletPostfix(overrideConfig) {
  const hasHubletOverride = overrideConfig && overrideConfig.hubletOverride;
  const hubletToUse = hasHubletOverride ? overrideConfig.hubletOverride : _enviro.default.getHublet();
  if (hubletToUse === Hublets.na1) {
    return '';
  }
  return `-${hubletToUse}`;
}
function getSubDomain(prefix, overrideConfig) {
  const hasHubletPostfixOverride = overrideConfig && overrideConfig.hubletPostfixLocation && overrideConfig.hubletPostfixLocation === 'domain';
  if (hasHubletPostfixOverride) {
    return prefix;
  }
  if (prefix === _subdomains.Subdomains.APP_API) {
    prefix = _subdomains.Subdomains.APP;
  }
  return `${prefix}${getHubletPostfix(overrideConfig)}`;
}
function getDomain(overrideConfig) {
  const domainPrefix = getDomainPrefix(overrideConfig);
  const envPostfix = getEnvPostfix(overrideConfig);
  const hubletDomainPostfix = getHubletDomainPostfix(overrideConfig);
  return `${domainPrefix}${envPostfix}${hubletDomainPostfix}`;
}
function getEnvPostfix(overrideConfig) {
  const hasEnvOverride = overrideConfig && overrideConfig.envOverride;
  const envToUse = hasEnvOverride ? overrideConfig.envOverride : _enviro.default.getShort();
  if (envToUse === 'qa') {
    return 'qa';
  }
  return '';
}
function getDomainPrefix(overrideConfig) {
  const hasDomainOverride = overrideConfig && overrideConfig.domainOverride;
  if (hasDomainOverride) {
    return overrideConfig.domainOverride;
  }
  return 'hubspot';
}
function getHubletDomainPostfix(overrideConfig) {
  const hasHubletPostfixxOverride = overrideConfig && overrideConfig.hubletPostfixLocation && overrideConfig.hubletPostfixLocation === 'domain';
  if (!hasHubletPostfixxOverride) {
    return '';
  }
  return getHubletPostfix(overrideConfig);
}
function getTld(overrideConfig) {
  const hasTldOverride = overrideConfig && overrideConfig.tldOverride;
  if (hasTldOverride) {
    return overrideConfig.tldOverride;
  }
  return 'com';
}
function getPathPrefix(subDomainPrefix) {
  if (subDomainPrefix === _subdomains.Subdomains.APP_API) {
    return '/api';
  }
  return '';
}